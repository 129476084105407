import React from "react";

import { header, verticals, verticalCard } from "./brandcrush-for-business.module.scss";

import Layout from "../layouts/Layout";
import VerticalCard from "../partials/VerticalCard";

const rootHref = "/brandcrush-for-business";

const BrandcrushForBusinessPage = props => {
  return (
    <Layout
      pageTitle="Solutions | Brandcrush"
      ogTitle="Brandcrush: Retail Media, Activations and Partnerships Platform | Business solutions"
      ogImage="/images/d2c@3x.png"
      ogDescription="From grocery and D2C retailers, shopping centres, gyms and universities, Brandcrush powers businesses to monetize and maximize their owned-media potential."
    >
      <section className={header}>
        <h1>Solutions for your business</h1>
        <p>
          Brandcrush is an end-to-end media monetization platform powering businesses around the
          world to monetize and maximize their omnichannel media assets. Discover how Brandcrush can
          transform your business.
        </p>
      </section>
      <section className={verticals}>
        <VerticalCard
          className={verticalCard}
          heading="Retail & grocery"
          body="Transform your in-store, out-of-store and high-engagement retail media from PDF packs and manual booking into a seamless, online platform."
          imageSrc="/images/retail@3x.png"
          imageAlt=""
          href={`${rootHref}/retail-grocery`}
        />
        <VerticalCard
          className={verticalCard}
          heading="D2C retail & food delivery"
          body="Monetize your home deliveries and rideshare trips to give your customers an in-home experience they’ll love."
          imageSrc="/images/d2c@3x.png"
          imageAlt=""
          href={`${rootHref}/d2c-retail-food-delivery`}
        />
        <VerticalCard
          className={verticalCard}
          heading="Shopping centres/malls"
          body="Digitize your short term leasing and advertising bookings making it seamless for clients to book  pop-ups, brand placements and sponsorships."
          imageSrc="/images/shopping@3x.png"
          imageAlt=""
          href={`${rootHref}/shopping-centres-malls`}
        />
        <VerticalCard
          className={verticalCard}
          heading="Health & wellness"
          body="Work with like-minded brands to give your clients fun experiences and great perks."
          imageSrc="/images/health@3x.png"
          imageAlt=""
          href={`${rootHref}/health-wellness`}
        />
        <VerticalCard
          className={verticalCard}
          heading="Corporate & coworking"
          body="Enliven your work spaces and delight members with branded pop-ups, demos and free samples."
          imageSrc="/images/corporate@3x.png"
          imageAlt=""
          href={`${rootHref}/corporate-coworking`}
        />
        <VerticalCard
          className={verticalCard}
          heading="Universities"
          body="Maximize and scale your on campus and online media opportunities through a seamless, online booking system."
          imageSrc="/images/university@3x.png"
          imageAlt=""
          href={`${rootHref}/universities`}
        />
        <VerticalCard
          className={verticalCard}
          heading="Hospitality & tourism"
          body="Treat your guests to amazing free samples and brand offers, all while creating a new revenue stream."
          imageSrc="/images/hospitality@3x.png"
          imageAlt=""
          href={`${rootHref}/hospitality-tourism`}
        />
      </section>
    </Layout>
  );
};

export default BrandcrushForBusinessPage;
