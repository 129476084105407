import React from "react";
import { navigate } from "gatsby";

import { base, content, link } from "./VerticalCard.module.scss";

import Link from "../components/Link";

import { combine } from "../utils/classNames";

const VerticalCard = props => {
  const { className, heading, body, imageSrc, imageAlt, href } = props;

  const handleClick = () => navigate(href);

  return (
    <div className={combine(base, className)}>
      <img src={imageSrc} alt={imageAlt} onClick={handleClick} />
      <h3>{heading}</h3>
      <div className={content}>
        <p>{body}</p>
        <Link className={link} href={href}>
          Learn more
        </Link>
      </div>
    </div>
  );
};

export default VerticalCard;
